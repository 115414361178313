/*
All split test experiments happen here.

An experiment has one or more sections, each with one or more variants.

A variant is a function that is passed a context and returns a JSON object.

Note that it must be plain JSON, no functions.

If there are multiple sections, these javascript objects are merged.
*/

const isReview = ({ path }) => path && path.startsWith("/review/");

const experiments = [
  /*
  {
    slug: "headerType",
    id: "tkbdcicPTOysBOSJJfmIwA",
    eligible: , isReview,
    sections: [
      // section 1
      [
        () => ({ headerType: "original" }),
        // () => ({ headerType: "hidden" }), // we know this is better, but not MUCH better
        () => ({ headerType: "transparent" }),
      ],
    ],
  },
  */
  /*
  {
    slug: "modifyLogo",
    id: "rz64zLoGRAKgtVxY2a5hOA",
    sections: [
      // section 1
      [() => ({}), () => ({ logoSize: 100 }), () => ({ logoLightning: false })],
    ],
  },
  */
  /*
  {
    slug: "testReview",
    id: "WojlN6DISnqIH-qYOWg0xw",
    eligible: isReview,
    sections: [[() => ({ testReview: false }), () => ({ testReview: true })]],
  },
  */
];

export function mergeExperimentData(experiments) {
  const results = {};
  experiments.forEach((experiment) => Object.assign(results, experiment.result));
  return results;
}

export function runExperiments({ query, path, cookies }) {
  return experiments
    .map((experiment) => {
      const { id, slug, sections, eligible } = experiment;
      if (!eligible({ query, path, cookies })) {
        return null;
      }
      const command = query[slug];
      let variantsCookie = cookies[id];
      let variant;
      const result = {};
      if (command === "clear") {
        // clearing
        console.log("Experiment", slug, "ignoring cookie");
        variantsCookie = null;
      }
      if (command && command !== "clear") {
        console.log("Experiment", slug, "processing command", command);
        variant = command
          .split("-")
          .map((v) => parseInt(v)) // avoid passing radix
          .map((variantId, sectionId) => {
            Object.assign(result, sections[sectionId][variantId]());
            return variantId;
          })
          .join("-");
        console.log("Experiment", slug, "set manually to", result);
      } else {
        if (variantsCookie) {
          console.log("Experiment", slug, "processing cookie", variantsCookie);
          variant = variantsCookie
            .split("-")
            .map((v) => parseInt(v)) // avoid passing radix
            .map((variantId, sectionId) => {
              Object.assign(result, sections[sectionId][variantId]());
              return variantId;
            })
            .join("-");
          console.log("Experiment", slug, "using cookie treatment", variant, result);
        } else {
          console.log("Experiment", slug, "randomizing");
          variant = sections
            .map((section) => {
              const variantId = parseInt(Math.random() * section.length);
              Object.assign(result, section[variantId]());
              return variantId;
            })
            .join("-");
          console.log("Experiment", slug, "using random treatment", variant, result);
        }
      }
      return {
        id,
        slug,
        variant,
        result,
      };
    })
    .filter((experiment) => experiment !== null);
}
